<template>
  <div class="EduHome">
    <LoginHeader />
    <main class="myAccount-container">
      <div class="MyAccountMian">
        <div class="MyAccountTitle">我的账户</div>
        <div class="MyAccountFont">我的账户编号：01545784475417458747</div>
        <div class="MyAccountToggle">
          <div
            @click="MyAccountToggleFn(i)"
            v-for="(item, i) in MyAccountToggleList"
            :key="i"
            :class="{ on: MyAccountToggleIndex == i }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="MyAccountCenter">
          <ul v-if="MyAccountToggleIndex === 0">
            <li>
              <personal-info />
            </li>
          </ul>
          <ul v-if="MyAccountToggleIndex === 1">
            <li><calendar-content /></li>
          </ul>
          <ul v-if="MyAccountToggleIndex===2">
            <li>33333</li>
          </ul>
          <ul v-if="MyAccountToggleIndex===3">
            <li>44444</li>
          </ul>
        </div>
      </div>
    </main>
    <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com  粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import LoginHeader from "@/components/LoginHeader";
import PersonalInfo from "./components/PersonalInfo/index.vue";
import myTimeBank from "./components/myTimeBank/index.vue"
export default {
  name: "MyAccount",
  data() {
    return {
      MyAccountToggleIndex: 0,
      MyAccountToggleList: [
        {
          name: "完善个人信息",
          id: 1,
        },
        {
          name: "查看时间银行余额",
          id: 2,
        },
        {
          name: "查看我的课程",
          id: 3,
        },
        {
          name: "查看学习情况",
          id: 4,
        },
      ],
    };
  },
  components: {
    LoginHeader,
    PersonalInfo,
    myTimeBank,
  },
  created() {},
  methods: {
    MyAccountToggleFn(r) {
      this.MyAccountToggleIndex = r;
    },
  },
};
</script>

<style lang="scss" scoped>
.myAccount-container {
  flex: 1;
  padding: 35px 40px 42px;
  margin-top: 60px;
  .MyAccountMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .MyAccountTitle{
      font-size: 35px;
    }
    .MyAccountFont{
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 0px;
      color: #666666;
      
      line-height: 36px;
      margin-top: 10px;
    }
    .MyAccountToggle{
      height: 50px;
      margin-top: 15px;
      div {
        cursor: pointer;
        float: left;
        width: calc(25% - 1px);
        margin-left: 1px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #666666;
        line-height: 48px;
        text-align: center;
        &.on{
          height: 50px;
          background: #303030;
          border: 1px solid #CCCCCC;
          
          border-radius: 6px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0px;
          color: #FFFFFF;
          line-height: 48px;
        }
      }
    }
    .MyAccountCenter {
      height: 441px;
      //background: #ffffff;
      border: 1px solid #a5a5a5;

      border-radius: 6px;
      margin-top: 15px;
      // padding: 10px 15px;
      line-height: 24px;
      overflow: auto;
      background: #f2f3f3;
      // ul {
      //   li {
      //     padding: 5px 0;
      //   }
      // }
    }
  }
}

.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter{
  border-top:1px solid #BFBFBF;
  .FooterCenter{
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul{
      float: left;
      li{
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight{
      float: right;
      text-align: right;
      p{
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
