<template>
  <div class="myTimeBank">
    <div class="list">
      <div class="item">
        <h3>查看时间银行余额</h3>
        <p>2021年8月21日下午01:20东部时间</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myTimeBank",
  components: {},
  data() {
    const list = Array(5).fill({
      title: "课时倒计时",
      time: "2021年8月21日下午01:20东部时间",
    });
    return {
      list,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.list {
  height: 100%;
  margin: 0 auto;
  flex: 1;
  width: 100%;
  .item {
    h3 {
      font-size: 16px;
      margin-bottom: 3px;
    }

    p {
      font-size: 12px;
    }
  }
}
</style>
