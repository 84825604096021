<template>
  <div class="PersonalInfo">
    <main class="personal-page-container">
      <div class="PersonalInfoMian">
        <div class="PersonalInfoFrom">
          <div class="PersonalInfoFromTitle">基本信息</div>
          <div class="PersonalInfoFromTable">
            <table width="100%">
              <tr>
                <td colspan="2">
                  <div class="FontTitle">姓名</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.fullname"
                      type="text"
                      placeholder="请填写姓名"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">手机号码</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.cellphone"
                      type="text"
                      placeholder="请填写手机号码"
                    />
                  </div>
                </td>
                <td>
                  <div class="FontTitle">上传自己头像</div>
                  <div>
                    <input
                      type="file"
                      id="file"
                      name="upload_image"
                      accept="image/gif, image/jpeg, image/png, image/jpg"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="FontTitle">邮箱</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.email"
                      type="text"
                      placeholder="请填写邮箱"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <button @click="RegisterBut" type="button" class="PersonalInfoButton">
            提交
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import BindWeChat from "./components/BindWeChat.vue";
// import BindWeChatTip from "./components/BindWeChatTip.vue"
import md5 from "js-md5";
import {
  getImageCaptcha,
  checkUsername,
  sendSmscode,
  register,
} from "@/api/authController";
export default {
  name: "PersonalInfo",
  data() {
    return {
      codeUrl: "",
      PersonalInfoFrom: {},
      RegisterFrom: {},
      show: true,
      count: "",
      timer: null,
      checkedInfo: true,
    };
  },
  components: {
    // BindWeChat,
    // BindWeChatTip,
  },
  created() {
    this.changeCode();
  },
  methods: {
    handleRoute() {
      return this.redirect === "/404" || this.redirect === "/403"
        ? "/home"
        : this.redirect;
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async RegisterBut() {
      //this.$refs["bindWeChat"].showEdit();
      //提交注册
      console.log(this.RegisterFrom);
      if (!this.RegisterFrom.cellphone) {
        this.$baseMessage(
          "请填写手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.RegisterFrom.cellphone)) {
        this.$baseMessage(
          "请输入有效的手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.captcha) {
        this.$baseMessage("请填写验证码", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.RegisterFrom.captcha.length != 4) {
        this.$baseMessage(
          "验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.verifier) {
        this.$baseMessage(
          "请填写手机验证码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (this.RegisterFrom.verifier.length != 6) {
        this.$baseMessage(
          "手机验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      //let reg = !/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}/   必须包含大小写字母 8-16
      if (!this.RegisterFrom.password) {
        this.$baseMessage("请填写密码", "warning", "vab-hey-message-warning");
        return;
      }
      if (
        this.RegisterFrom.password.length < 8 ||
        this.RegisterFrom.password.length > 16
      ) {
        this.$baseMessage(
          "密码必须在8-16的范围内",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.password1) {
        this.$baseMessage(
          "请输入第二次密码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (this.RegisterFrom.password != this.RegisterFrom.password1) {
        this.$baseMessage(
          "两次输入的密码不一致",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.checkedInfo) {
        this.$baseMessage(
          "协议条款未填写",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      let json = {};
      json.cellphone = this.RegisterFrom.cellphone;
      const { code, data } = await checkUsername(json);
      if (code == 20000 && data.exists) {
        this.$baseMessage(
          "手机号码已注册",
          "warning",
          "vab-hey-message-warning"
        );
      } else if (code == 20000 && !data.exists) {
        this.register();
      }
    },
    async register() {
      let regFrom = {};
      regFrom.username = this.RegisterFrom.cellphone;
      regFrom.password = md5(this.RegisterFrom.password);
      let verifierFrom = {};
      verifierFrom.verifier = this.RegisterFrom.verifier;
      verifierFrom.captcha = this.RegisterFrom.captcha;
      const { code, msg } = await register(regFrom, verifierFrom);
      if (code == 20000) {
        this.$baseMessage("注册成功", "success", "vab-hey-message-success");
        this.$router.push({
          path: "/Login",
        });
      } else {
        this.$baseMessage(
          "注册失败,code:" + code + ",msg:" + msg,
          "error",
          "vab-hey-message-error"
        );
      }
    },
    changeCode() {
      this.codeUrl = getImageCaptcha() + "?timestamp=" + new Date().getTime();
    },
    checkSendSmscode() {
      if (!this.RegisterFrom.cellphone) {
        this.$baseMessage(
          "请填写手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      var reg = /^1[3456789]\d{9}$/;
      if (!reg.test(this.RegisterFrom.cellphone)) {
        this.$baseMessage(
          "请输入有效的手机号码",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      if (!this.RegisterFrom.captcha) {
        this.$baseMessage("请填写验证码", "warning", "vab-hey-message-warning");
        return;
      }
      if (this.RegisterFrom.captcha.length != 4) {
        this.$baseMessage(
          "验证码位数不对",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      this.checkCellphone();
    },
    async checkCellphone() {
      let json = {};
      json.username = this.RegisterFrom.cellphone;
      const { code, data } = await checkUsername(json);
      if (code == 20000 && data.exists) {
        this.$baseMessage(
          "手机号码已注册",
          "warning",
          "vab-hey-message-warning"
        );
      } else if (code == 20000 && !data.exists) {
        this.sendSmscode();
      } else {
        this.$baseMessage("请求超时请重试", "error", "vab-hey-message-error");
      }
    },
    async sendSmscode() {
      let json = {};
      json.captcha = this.RegisterFrom.captcha;
      json.cellphone = this.RegisterFrom.cellphone;
      const { code } = await sendSmscode(json);
      if (code == 20000) {
        this.getCode();
      } else {
        this.$baseMessage("请求超时请重试", "error", "vab-hey-message-error");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-page-container {
  flex: 1;
  .PersonalInfoMian {
    width: 100%;
    margin: 0 auto;
    color: #333333;
    .PersonalInfoTitle {
      font-size: 35px;
    }
    .PersonalInfoItem {
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      line-height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;

      i {
        float: left;
        width: 24px;
        height: 24px;
        display: block;
        background: url("../../../../assets/register/item-icon.png") no-repeat
          center;
        background-size: cover;
        margin-right: 10px;
      }
    }
    .PersonalInfoFrom {
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      padding: 15px 100px;
      .PersonalInfoFromTitle {
        font-size: 30px;
        line-height: 60px;
      }
      .PersonalInfoFromTable {
        margin: 0 -15px;
        border: 0;
        padding-bottom: 20px;
        td {
          padding: 0 15px;
          box-sizing: border-box;
          width: 50%;
          .FontTitle {
            line-height: 40px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .FontInput {
            height: 45px;
            background: #ffffff;
            border: 1px solid #989898;

            border-radius: 3px;
            box-sizing: content-box;
            font-size: 0;
            input {
              width: 100%;
              box-sizing: border-box;
              border: 0;
              padding: 0 15px;
              height: 45px;
              font-size: 16px;
              border: 0;
              background: transparent;
            }
          }
        }
      }
    }
    .PersonalInfoButton {
      background: #173d7c;
      border-radius: 10px;
      cursor: pointer;
      border: 0;
      font-size: 16px;
      color: #ffffff;
      margin-top: 10px;
      width: 149px;
      height: 50px;

      &:hover {
        background: #244f96;
      }
    }
  }
}
.FontButton {
  padding: 0 15px;
  height: 45px;
  font-size: 16px;
  border: 1px solid #989898;
  color: #989898;
}
.code {
  cursor: pointer;
  height: 45px;
  margin-top: 3px;
}

.login-protocol {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  span {
    color: #173d7c;
  }
}

.PersonalInfo {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
